<template>
  <div class="utils">
    <main>
      <div class="images">
        <div class="left"> {{ that.$route.query.zmc }} </div>
        <div class="right">
          <img src="../../../assets/student/xgnjiantou.png" @click="that.$router.replace({ name: 'newEnglish' })" alt="" style="cursor: pointer">
        </div>
      </div>
      <div class="swipe" v-if="isShow">
        <el-carousel :autoplay="false" arrow="never" id="cardShow" ref="dropdownRef">
          <el-carousel-item v-for="item in allList" :key="item">
            <div class="swipeitem" v-for="ele in item" :key="ele" @click="gochapter(ele)">
              <img v-if="ele" src="../../../assets/student/borderblock.png" alt="">
              <!-- <img v-else src="../../../assets/student/xgnblock.png" alt=""> -->
              <span>{{ele.mc}}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </main>
    <Header :header_data="header_data"/>
    <Footer />
  </div>
</template>
<script>
import { getTpDyList } from '@/api/student.js'
import { getCurrentInstance, nextTick, toRefs } from 'vue'
import { onMounted, reactive } from 'vue-demi';
import Header from "../components/zjfheader.vue";
import Footer from "../components/footer.vue";
import { ElMessage } from 'element-plus';
export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const gochapter = (ele) => {
      that.$router.replace({name: 'chapter', query: { dyid: ele.id, dymc: ele.mc, zmc: that.$route.query.zmc, zid: that.$route.query.zid }})
    }
    const data = reactive({
      jxInfo: {},
      allList: [],
      isShow: true, // 轮播图dom重新加载
    })
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties;
    const header_data = [
      {title: '图谱英语 — ', path: 'newEnglish'},
      {title: that.$route.query.zmc, path: ''},
    ]
    onMounted(() => {
      getTpDyList({
        zid: ~~that.$route.query.zid
      }).then(res => {
        data.isShow = false
        let arr = []
        res.result.forEach((ele, index) => {
          if (!arr[parseInt(index / 8)]) {
            arr[parseInt(index / 8)] = []
          }
          arr[parseInt(index / 8)].push(ele)
        })
        data.allList = arr
        nextTick(() => {
          data.isShow = true
        })
      })
    })
    return {
      gochapter,
      header_data,
      ...toRefs(data),
      that
    }
  },
};
</script>
<style lang="scss" scoped>
.utils {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/mnemonic.png") no-repeat;
  background-size: 100% 100%;
  main {
    width: 84.37%;
    height: 72.81%;
    background: url("../../../assets/student/xgnbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    .images {
      .left {
        width: 22.6vw;
        height: 7.3vw;
        background: url("../../../assets/student/xgntitle.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -1.5vw;
        left: -0.5vw;
        font-size: 1.7vw;
        color: #fff;
        text-align: center;
        line-height: 6.8vw;
      }
      .right {
        position: absolute;
        right: 3.5vw;
        top: -1vw;
        width: 9.687vw;
        height: 4.583vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      z-index: 2;
    }
    .swipe {
      width: 84%;
      height: 80%;
      margin: 7.5% 0% 0 10%;
      .swipeitem {
        float: left;
        margin-right: 3.4vw;
        margin-bottom: 1vw;
        width: 14.7vw;
        height: 12vw;
        position: relative;
        text-align: center;
        line-height: 12vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        span {
          position: absolute;
          bottom: 5%;
          left: 50%;
          font-size: 2.5vw;
          transform: translateX(-50%);
          color: #fff;
        }
      }
      .swipeitem:nth-child(4) {
        margin-right: 0;
      }
      .swipeitem:nth-child(8) {
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.utils {
  .el-progress-bar__outer {
    background: #fff !important;
    height: 16px !important;
  }
  .el-progress-bar__inner {
    background: #0972e7;
  }
  .el-carousel--horizontal {
    height: 100%;
  }
  .el-carousel__container {
    height: 100%;
  }
  .el-carousel__indicators {
    bottom: 1vw;
  }
  .el-carousel__indicator {
    .el-carousel__button {
      width: 1.8vw;
      height: 0.6vw;
      border-radius: 6px;
      background: #fff;
      opacity: 1;
    }
  }
  .el-carousel__indicator.is-active {
    .el-carousel__button {
      background: #5990ff;
    }
  }
}
</style>